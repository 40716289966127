import React from "react"
import { Helmet } from "react-helmet"
import { Emails } from "../config/db"
import { useQuery } from "react-query"
import useQueryParams from "../hooks/useQueryParams"
import { navigate } from "gatsby"

const UserManagement = () => {
  const query = useQueryParams()
  const email = query.get("email")
  useQuery("UserManagement - fetchEmail", async () => {
    if (!email) navigate("/")

    const fetchedEmail = await Emails.fetchEmail("email", email)
    if (!fetchedEmail) Emails.create({ email, subscribed: false })
    else Emails.update({ email, subscribed: false }, fetchedEmail.id)
  })

  return (
    <>
      <Helmet>
        <title>Management</title>
      </Helmet>
      <h1>You have successfully been removed from our mailing list!</h1>
    </>
  )
}

export default UserManagement
